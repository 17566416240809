import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController, AlertOptions, ViewWillLeave } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { PwaUpdateEvent, UpdateService } from '../../providers/servicer/update-service';
import * as Moment from 'moment';
import { AlertType } from '../../app/services/servicer/models/update-alert-model';
import { Observable, Subscription, of, throwError, BehaviorSubject } from 'rxjs';
import { mergeMap, retry } from 'rxjs/operators';
import { StorageWrapperService } from '../../app/services/storage-wrapper.service';
import { VersionInfo } from '../types/version-info';

@Injectable({
  providedIn: 'root'
})
export class UpdatesAlertService implements ViewWillLeave {
  //unsubscribe用のリスト
  private subscriptions = new Subscription();

  //更新のタイムアウト時間
  private timeoutUpdating: NodeJS.Timeout;

  //タイムオーバーフラグ
  private isTimeOver: boolean;

  /** 表示なし */
  private readonly alertIdNone = 'NONE';

  /** 現在時刻 */
  private todayDate: string;

  private readonly successHttpStatus = 200;

  /** 更新成功 */
  private readonly alertIdUpdateSuccess: AlertType = {
    header: '',
    message: '更新が完了しました<br>再起動します',
    alertId: 'ALERT_UPDATE_SUCCESS',
    scssName: 'versionUpNoButton',
    alertTimeout: new BehaviorSubject<boolean>(false)
  };

  /** 更新 */
  private readonly alertIdWithUpdates: AlertType = {
    header: '',
    message: '新しいバージョン<br>が見つかりました<br>更新します',
    alertId: 'ALERT_UPDATE_CONFIRM',
    scssName: 'versionUpNoButton',
    alertTimeout: new BehaviorSubject<boolean>(false)
  };

  /** 更新中 */
  private readonly alertIdUpdating: AlertType = {
    header: '更新中...',
    message: '',
    alertId: 'ALERT_UPDATING',
    scssName: 'versionUpdating',
    alertTimeout: new BehaviorSubject<boolean>(false)
  };

  /** 更新失敗 */
  private readonly alertIdFailed: AlertType = {
    header: '',
    message: '通信環境を確認後<br>再起動してください',
    alertId: 'ALERT_FAILED',
    scssName: 'updateFailed',
    button: [
      {
        text: 'OK',
        role: 'OK',
        handler: () => {
          //ブラウザ更新
          document.location.reload();
        }
      }
    ],
    alertTimeout: new BehaviorSubject<boolean>(false)
  };

  /** 現在表示画面 */
  private isShowAlertId = this.alertIdNone;

  private readonly STORAGE_KEY_LAST_OPEN_APP_DATE: string = 'lastOpenAppDate';
  private readonly STORAGE_KEY_IS_UPDATE_FAILED: string = 'faildUpdate';

  constructor(
    private alertCtrl: AlertController,
    private swUpdate: SwUpdate,
    private updateService: UpdateService,
    private storageWrapperService: StorageWrapperService
  ) {
    this.updateService.setSwUpdate(this.swUpdate);
    this.updateService.setUpSwUpdateSubscriber();
  }

  ionViewWillLeave(): void {
    if (this.subscriptions) {
      if (!this.subscriptions.closed) {
        this.subscriptions.unsubscribe();
      }
    }
  }

  /**バージョンアップ確認のメイン関数 */
  public async checkForUpdateVersion() {
    const TRUE_STR = 'true';

    //ServiceWorkerの起動確認
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.todayDate = Moment(new Date()).format('YYYY/MM/DD');

    //ストレージから前回起動日付を取得
    this.getOpenAppDate().then((storageDate: string) => {
      if (storageDate) {
        if (storageDate === this.todayDate) {
          return;
        }
      }
      //S3サーバーからバージョン取得とバージョン比較処理
      this.subscriptions.add(this.tryGetVersionNoForS3().subscribe(async (s3Version: string) => {
        if (s3Version === environment.appVersion) {
          this.getIsFailedUpdateFlag().then((failedFlag: string) => {
            if (failedFlag !== TRUE_STR) {
              // S3バージョンと一致かつ、前回失敗アラートを表示して居なければ日時保存
              this.storageWrapperService.setStorage(this.STORAGE_KEY_LAST_OPEN_APP_DATE, this.todayDate);
            } else {
              // S3バージョンと一致したが前回失敗アラートを出していた場合は一連のアラートを表示する
              // ※更新自体は成功しているがユーザーに通知できていないのでアラートのみ表示、更新自体は実行しない
              this.storageWrapperService.removeStorage(this.STORAGE_KEY_IS_UPDATE_FAILED);

              //「更新があります」アラートの表示
              this.showAlertModal(this.alertIdWithUpdates);

              //更新中アラートの表示
              this.showAlertModal(this.alertIdUpdating);

              //更新成功アラートの表示
              this.showAlertModal(this.alertIdUpdateSuccess);

              this.subscriptions.add(this.alertIdUpdateSuccess.alertTimeout.subscribe((reloadFlag: boolean) => {
                if (reloadFlag) {
                  document.location.reload();
                }
              }));
            }
          });
        } else {
          //「更新があります」アラートの表示
          this.showAlertModal(this.alertIdWithUpdates);

          //更新中アラートの表示
          this.showAlertModal(this.alertIdUpdating);
          this.tryUpdate();
        }
      }));

    });
  }

  /**
   * 更新処理中状態
   *
   * @returns true:更新処理中 , false: 更新未動作
   */
  public isUpdating(): boolean {
    if (this.isShowAlertId === this.alertIdNone) {
      return false;
    }
    return true;
  }

  /** ストレージから、アプリ最終起動日付を取得 */
  private async getOpenAppDate(): Promise<string> {
    return await this.storageWrapperService.getStorage(this.STORAGE_KEY_LAST_OPEN_APP_DATE);
  }

  /** ストレージから、更新失敗フラグを取得 
   *  AngularのlocalStorageは、String型しか扱えないので文字型のフラグを返す 
   * 
  */
  private async getIsFailedUpdateFlag(): Promise<string> {
    return await this.storageWrapperService.getStorage(this.STORAGE_KEY_IS_UPDATE_FAILED);
  }

  /**
   * s3のバージョン番号ファイルの値を取得,取得に失敗した場合は、再度取得を試みる（リトライは、5回まで）
   *
   * @returns s3バージョン
   */
  private tryGetVersionNoForS3(): Observable<string> {
    return new Observable<string>((res) => {
      const getS3VerResult: Observable<string> = this.getVersionNoForS3().pipe(
        mergeMap(s3Ver => s3Ver ? of(s3Ver) : throwError('failed getS3Ver')),
        retry(4)
      );

      this.subscriptions.add(getS3VerResult.subscribe({
        next: s3Ver => res.next(s3Ver),
        //通信障害アラートモーダル表示 S3の取得に失敗した場合は更新中アラートを待たずに更新失敗を表示する
        error: () => this.showAlertModal(this.alertIdFailed, true)
      }));
    });
  }


  /**
   * s3のバージョン番号ファイルの値を取得する
   *
   * @returns s3から取得したバージョン番号
   */
  private getVersionNoForS3(): Observable<string> {
    return new Observable<string>((s3Ver) => {
      fetch(environment.addressVersionUrl, {
        method: 'GET'
      }).then(async res => res.status === this.successHttpStatus ? s3Ver.next(this.getVersionNoForJsonFile(await res.text())) : s3Ver.next(null));
    });
  }

  /**
   * バージョン管理ファイルをJSONデータに変換後、パース処理をしてバージョン番号を取得する
   *
   * @param versionFile s3から取得したバージョン管理ファイル
   * @returns バージョン番号
   */
  private getVersionNoForJsonFile(versionFile: string): string {
    try {
      let resJson = JSON.parse(versionFile) as VersionInfo;
      const envArea = environment.type;

      switch (envArea) {
        //SPOKE環境を起動している場合、バージョン管理ファイルからSPOKE環境のバージョン番号を取得する
        case 'spoke':
          return resJson.spoke.mini;
        //HUB環境を起動している場合、バージョン管理ファイルからHUB環境のバージョン番号を取得する
        case 'hub':
          return resJson.hub.mini;
        default:
          return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  /**
   * 更新情報取得と、更新実行を3回まで実行する
   */
  private async tryUpdate(): Promise<void> {
    const updateResult: Observable<boolean> = this.update().pipe(
      mergeMap(isSuccess => {
        //タイムアウト処理をクリア
        if (this.timeoutUpdating) {
          clearTimeout(this.timeoutUpdating);
        }
        //更新情報取得と更新成功
        if (isSuccess) {
          return of(isSuccess);
        } else {
          throwError(() => 'failed update');
        }
      }),
      retry(2)
    );

    this.subscriptions.add(updateResult.subscribe(
      () => {
        // アップデートに成功
        this.showAlertModal(this.alertIdUpdateSuccess);
        this.storageWrapperService.setStorage(this.STORAGE_KEY_LAST_OPEN_APP_DATE, this.todayDate);
        this.subscriptions.add(this.alertIdUpdateSuccess.alertTimeout.subscribe((reloadFlag: boolean) => {
          if (reloadFlag) {
            document.location.reload();
          }
        }));
      },
      // アップデート失敗
      (error) => {
        this.showAlertModal(this.alertIdFailed);
      }
    ));
  }

  /**更新情報取得と、更新実行 */
  private update(): Observable<boolean> {
    return new Observable<boolean>((observe) => {
      //タイムアウトフラグを初期化
      this.isTimeOver = false;

      //タイムアウト起動
      this.timeoutUpdating = setTimeout(() => {
        this.isTimeOver = true;
        observe.next(false);
      }, environment.pwaUpdatingTimeout);

      this.isGetUpdateInfoSuccess().then((updateInfoFlag: boolean) => {
        //更新情報取得成功
        if (updateInfoFlag && !this.isTimeOver) {
          observe.next(true);
        } else {
          observe.next(false);
        }
      });
    });
  }

  /**
   * 更新確認（ServiceWorker)
   *
   */
  private async isGetUpdateInfoSuccess(): Promise<boolean> {
    // 更新情報取得
    const evt: PwaUpdateEvent = await this.updateService.pwaUpdateConfirm();
    return evt === PwaUpdateEvent.eventCheckForUpdateFound && !this.isTimeOver;
  }

  /**
   * targetAlertの生成
   *
   * @param targetAlert 表示するアラートタイプ
   */
  private showAlertModal(targetAlert: AlertType, skipFlg?: boolean): void {
    if (environment.operationDuringPwaUpdate) {
      return;
    }
    this.isShowAlertId = targetAlert.alertId;

    // 画面情報生成
    const alertOption: AlertOptions = {
      id: targetAlert.alertId,
      header: targetAlert.header,
      message: targetAlert.message,
      cssClass: targetAlert.scssName,
      buttons: targetAlert.button,
      translucent: true,
      backdropDismiss: false,
      animated: false,
    };

    this.subscriptions.add(
      this.createAlert(alertOption).subscribe((createdAlert) => {
        if (targetAlert.alertId === this.alertIdWithUpdates.alertId) {
          createdAlert.present();
          setTimeout(() => this.alertIdWithUpdates.alertTimeout.next(true), environment.pwaDisplayTimeout);
        }
        if (targetAlert.alertId === this.alertIdUpdating.alertId) {
          this.presentAlert(createdAlert, this.alertIdUpdating, this.alertIdWithUpdates);
        }
        if (targetAlert.alertId === this.alertIdFailed.alertId) {
          this.presentAlert(createdAlert, this.alertIdFailed, this.alertIdUpdating, skipFlg);
          //ストレージに更新失敗フラグを保存
          this.storageWrapperService.setStorage(this.STORAGE_KEY_IS_UPDATE_FAILED, 'true');
        }
        if (targetAlert.alertId === this.alertIdUpdateSuccess.alertId) {
          this.presentAlert(createdAlert, this.alertIdUpdateSuccess, this.alertIdUpdating);
        }
      })
    );

  }
  /**
   *　アラートを生成
   *
   * @param alert 生成に必要な情報
   * @returns 生成したアラートのインスタンス
   */
  private createAlert(alert: AlertOptions): Observable<HTMLIonAlertElement> {
    return new Observable<HTMLIonAlertElement>((res) => {
      this.alertCtrl.create(alert).then((createdAlert) => {
        res.next(createdAlert);
      });
    });
  }
  /**
   *　waitingAlertの最低表示時間経過後、targetAlertを表示
   *
   * @param createdAlert 生成したアラートのインスタンス
   * @param targetAlert 表示するアラートタイプ
   * @param waitingAlert 閉じるアラートタイプ
   * @param skipFlg 閉じるアラートの最低表示時間を待たずに表示アラート表示する場合にTrue
   */
  private presentAlert(createdAlert: HTMLIonAlertElement, targetAlert: AlertType, waitingAlert: AlertType, skipFlg?: boolean): void {
    if (!skipFlg) {
      this.subscriptions.add(
        waitingAlert.alertTimeout.subscribe((res) => {
          if (res) {
            createdAlert.present();
            this.alertCtrl.dismiss(undefined, undefined, waitingAlert.alertId);
            setTimeout(() => targetAlert.alertTimeout.next(true), environment.pwaDisplayTimeout);
          }
        })
      );
    } else {
      createdAlert.present();
    }
  }
}
