import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageWrapperService {
  constructor(
    ) { }
  
    public setStorage(key: string, value: string): void{
      localStorage.setItem(key, value);
    }
  
    public getStorage(key: string): Promise<string> {
      return new Promise((resolve) => {
        resolve(localStorage.getItem(key));
      });
    }
  
    public removeStorage(key: string): void{
      localStorage.removeItem(key);
    }  
}
