import { environment } from '../../environments/environment';

export class MessageLoader {
  private messageload = '';
  constructor() { }

  async load() {
    await fetch(environment.addressMessageBoardUrl, {
      method: 'GET'
    })
      .then(res => res.text())
      .then(data => {
        const array = data.split('\r\n').join(('<br>'));
        this.messageload = array;

      });
  }

  public getMessege() {
    return this.messageload;
  }
}