import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


import { environment } from '../../environments/environment';
import { GenericList } from './models/generic-list';
import { Const } from '../../providers/const';

/**
 * サービサーのプロバイダー.
 */
@Injectable()
export class ServicerProvider {
  constructor(
    public http: HttpClient
  ) { }

  /**
   * パラメータをbodyに変換.
   *
   * @param parameter パラメータ
   * @return body
   */
  parameterToBody<T>(parameter: T): string {
    let body = '';
    let amp = '';

    Object.keys(parameter).map((key) => {
      const value = parameter[key];

      if (value !== null) {
        body += amp + key + '=' + value;
        amp = '&';
      }
    })

    return body;
  }

  /**
   * post実行.
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
  post<T, V>(path: string, parameter: T): Observable<V> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      withCredentials: true
    };
    return this.http.post<V>(this.getUrl(path), parameter, options);
  }

  /**
   * get実行(1件取得).
   *
   * @param path パス
   * @return レスポンス
   */
  get<T>(path: string): Observable<T> {
    const url = this.getUrl(path);
    const options = { headers: this.getHeader() };
    return this.http.get<T>(url, options).pipe(tap((res) => this.resultFail<T>(res)));
  }

  /**
   * get実行(list取得).
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
  list<T, V>(path: string, parameter?: T): Observable<GenericList<V>> {
    const url = this.getUrl(path);
    const params = this.toHttpParams(parameter);
    const options = { headers: this.getHeader() , params: params };
    return this.http.get<GenericList<V>>(url, options).pipe(tap((res) => this.resultFail<GenericList<V>>(res)));
  }

  /**
   * get実行(テキスト系取得).
   *
   * @param path パス
   * @return レスポンス
   */
   getText<T>(path: string): Observable<T> {
    //const url = this.getUrl(path);
    const url =environment.setting.servicerApiUrl+"/v2/image?name=user/icon/ukedon_500/06.png";
    const header = new HttpHeaders( {
      //responseType: 'text',
      'Content-Type':'image/png',
      'Access-Control-Allow-Origin': '*'
    });
    const options = { headers: header };

    return this.http.get<T>(url, options).pipe(tap((res) => this.resultFail<T>(res)));
  }



  /**
   * post実行(登録).
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
  postApi<T, V>(path: string, parameter: T): Observable<V> {
    const url = this.getUrl(path);
    const options = { headers: this.getHeader() };
    return this.http.post<V>(url, parameter, options).pipe(tap((res) => this.resultFail<V>(res)));
  }

  /**
   * FormDataのPOST.
   *
   * @param path パス
   * @param formData FormData
   * @return レスポンス
   */
  postFormData<V>(path: string, formData: FormData): Observable<V> {
    const options = { withCredentials: true };
    return this.http.post<V>(this.getUrl(path), formData, options);
  }

  /**
   * patch実行.
   *
   * @param path パス
   * @param parameter パラメータ
   * @return レスポンス
   */
  patch<T, V>(path: string, parameter: T): Observable<V> {
    const url = this.getUrl(path);
    const options = { headers: this.getHeader() };
    return this.http.patch<V>(url, parameter, options).pipe(tap((res) => this.resultFail<V>(res)));
  }

  /**
   * delete実行.
   *
   * @param path パス
   * @return レスポンス
   */
  delete<T>(path: string): Observable<T> {
    const url = this.getUrl(path);
    const options = { headers: this.getHeader() };
    return this.http.delete<T>(url, options).pipe(tap((res) => this.resultFail<T>(res)));
  }

  /**
   * ヘッダー作成.
   * 
   * @returns new HttpHeaders
   */
  private getHeader(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('content-type','application/json');
    return headers;
  }

  /**
   * URL取得.
   * 
   * @param path パス
   * @returns URL
   */
  private getUrl(path: string): string {
    return environment.setting.servicerApiUrl + path;
  }
  /**
   * オブジェクトをHTTPパラメータに変換する。
   *
   * @param params パラメータ
   * @return HTTPパラメータ
   */
  private toHttpParams(params: any): HttpParams {
    if (!params) {
      return null;
    }
    return Object.getOwnPropertyNames(params)
      .filter(key => params[key])
      .reduce((p, key) => p.set(key, params[key]), new HttpParams());
  }

  /**
   * 結果が0のエラー.
   * 
   * @param response APIレスポンス
   */
  private resultFail<T>(response: T): void {
    if (response['result'] === Const.ResponseResult.FAIL) {
      throw new Error(response['message']);
    }
  }
}
