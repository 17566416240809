import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, interval, of } from 'rxjs';
import { tap, map, catchError, filter, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiResultType } from '../../providers/servicer/types/api-result-type';
import { StationV2ServiceProvider } from '../servicer/station-v2-service';
import { StationReservationsV2Response } from '../servicer/models/station-reservations-v2-response';
import { ManageStorageAroundReservationCardsService } from '../servicer/reservations-check-status';

/**
 * 予約一覧を定期間隔で取得するプロバイダー.
 */
@Injectable()
export class ReservationsGetScheduleProvider {

  /** 予約一覧の空のレスポンス. */
  private readonly reservationsEmptyResponse = { result: ApiResultType.SUCCESS, message: '', reservations: [] } as StationReservationsV2Response;

  /** 予約一覧. */
  public reservations$: BehaviorSubject<StationReservationsV2Response>
    = new BehaviorSubject<StationReservationsV2Response>(this.reservationsEmptyResponse);

  /** インターバルのSubscription. */
  private intervalSubscription: Subscription = null;

  constructor(
    public stationV2Service: StationV2ServiceProvider,
    public manageStorageAroundReservationCardsService: ManageStorageAroundReservationCardsService
  ) {
  }

  /**
   * 開始.
   */
  start() {
    // 初回の予約一覧取得
    this.getReservations().subscribe(() => {
      let processing = false;

      this.intervalSubscription = interval(environment.setting.reservationsGetInterval)
        .pipe(filter(() => !processing))        
        .pipe(tap(() => processing = true))
        .pipe(mergeMap(() => this.getReservations()))
        .pipe(tap(() => processing = false))
        .subscribe();
    });
  }

  /**
   * 停止.
   */
  stop() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
  }

  /**
   * 予約一覧を取得します.
   * 定期的に呼び出されるため、エラーが発生しても、何も処理をしません.
   */
  private getReservations(): Observable<StationReservationsV2Response> {
    const stationSession = this.stationV2Service.getSession();

    return this.stationV2Service
      .reservations({
        stationId: stationSession.id,
        language: stationSession.language
      })
      .pipe(
        map((response) => {
          if (response.result === ApiResultType.SUCCESS) {
            this.reservations$.next(response);
            this.manageStorageAroundReservationCardsService.checkReservationUserId(response.reservations);
          }
          return this.reservations$.value;
        }),
        catchError(() => of(this.reservations$.value))
      );
  }
}