/**
 * ユーザステータス タイプ.
 */
export const UserStatusType = {
  /** エラー(0). 旧:0. */
  ERROR: 0,
  /** 配車依頼受付中(1). 旧 : 配車可能(1) / ENABLED. */
  ACCEPTING_DISPATCH_ORDER: 1,
  /** 乗降場所計画指示中(2). 旧 : 配車可能(1) / ENABLED. */
  BOARDING_LOCATION_PLANNING_ORDER: 2,
  /** 配車計画依頼中(3). 旧 : 配車可能(1) / ENABLED. */
  DISPATCH_PLANNING_ORDER: 3,
  /** 配車仮予約依頼中(4). 旧 : 配車可能(1) / ENABLED. */
  DISPATCH_TENTATIVE_RESERVATION_ORDER: 4,
  /** 配車予約確定確認中(5). 旧 : 配車可能(1) / ENABLED. */
  DISPATCH_RESERVATION_CHECK_CONFIRMATION: 5,
  /** 配車予約確定依頼中(6). 旧 : 配車可能(1) / ENABLED. */
  DISPATCH_RESERVATION_FIXED_ORDER: 6,
  /** 乗車地誘導中(7). 旧 : 配車中(2) / ALLOCATE_CARS. */
  GUIDANCE_RIDE_PLACE: 7,
  /** ユーザ待機中(8). 旧 : ユーザMP到着(3) / ARRIVE_AT_MP_USER. */
  WAITING_USER: 8,
  /** 車両待機中(9). 旧 : 車両MP到着(8) / ARRIVE_AT_MP_VEHICLE. */
  WAITING_VEHICLE: 9,
  /** 認証待機中(10). 旧 : MP到着(9) / ARRIVE_AT_MP_USER_AND_VEHICLE. */
  WAITING_AUTHENTICATION: 10,
  /** ユーザ認証中(11). 旧 : MP到着(9) / ARRIVE_AT_MP_USER_AND_VEHICLE. */
  USER_AUTHENTICATION: 11,
  /** 乗り込み待機中(12). 旧 : BLE認証完了(10) / BLE_AUTH. */
  WAITING_RIDE: 12,
  /** 乗り込み中(13). 旧 : 乗込中(ドアアンロック済)(11) / DOOR_UNLOCK. */
  RIDING: 13,
  /** 目的地移動中(14). 旧 : 乗車中(4) / MOVING. */
  MOVING_DESTINATION: 14,
  /** 降車地付近接近中(15). 旧 : DP付近(5) / NEAR_DP. */
  ACCESS_IN_DROP_OFF_PLACE_NEAR: 15,
  /** 降車待機中(16). 旧 : DP到着(6) / ARRIVE_AT_DP. */
  WAITING_DROP_OFF: 16,
  /** 降車中(17). 旧 : 降車中(12) / GETTING_OFF. */
  IN_DROP_OFF: 17,
  /** 目的地誘導中(18). 旧 : 閉扉後(13) / DOOR_LOCK. */
  GUIDANCE_DESTINATION: 18,
  /** 目的地到着(20). 旧 : 閉扉後(13) / DOOR_LOCK. */
  ARRIVED_DESTINATION: 20,
  /** 乗車タイムアウト(19). 旧 : MPタイムオーバー(7) / TIMEOVER. */
  RIDE_TIMEOUT: 19,
  /** 車両アクシデント発生中(99). 旧:なし. */
  VEHICLE_ACCIDENT_OCCURRENCE: 99
};

export type UserStatusType = typeof UserStatusType[keyof typeof UserStatusType];
