import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ServicerProvider } from './servicer';
import { UserVehicleSession } from './models/user-vehicle-session';

@Injectable()
export class UserVehicleV2ServiceProvider extends ServicerProvider {
  private sessionSource: BehaviorSubject<UserVehicleSession> = new BehaviorSubject<UserVehicleSession>(null);

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
  getSession(): UserVehicleSession {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: UserVehicleSession) {
    this.sessionSource.next(session);
  }
  
}
