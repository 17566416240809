import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StationForSearch } from './servicer/models/stationForSearch';
import { UserV2ServiceProvider } from '../../providers/servicer/user-v2-service';
import { ApiResultType } from '../../providers/servicer/types/api-result-type';
import { MapperUtil } from '../../app/utilities/mapper-util';
import { Station } from './servicer/models/station';
import { DoStationCodes } from './servicer/models/do-station-codes';
import { StationSession } from '../../providers/servicer/models/station-session';
import { Observable, Subject, Subscriber } from 'rxjs';

@Injectable()
export class StaticTableLoader {
  public serverCacheUpdateMode = 0;

  public readonly CODE_ROW_COLUMN = 0;
  public readonly TYPE_ROW_COLUMN = 1;
  public readonly NAME_ROW_COLUMN = 2;
  public readonly YOMI_ROW_COLUMN = 3;
  public readonly DISTRICT_L_COLUMN = 4;
  public readonly DISTRICT_S_COLUMN = 5;
  public readonly LAT_ROW_COLUMN = 6;
  public readonly LON_ROW_COLUMN = 7;
  public readonly ADDRESS_ROW_COLUMN = 8;
  public readonly NICKNAME_COLUMN = 10;
  public readonly NICKNAME_KANA_COLUMN = 11;
  public readonly KEYWORD_COLUMN = 12;
  public readonly KEYWORD_KANA_COLUMN = 13;

  public readonly defaultStationTables = {
    DoStationCodes: null,
    stationSession: null,
    assignName: '',
    displayName: '',
  };

  public readonly defaultConstTables = {
    userStations: null,
    timeSchedule: null,
    dataCsv: null,
    nextLoadDate: 0
  };

  public static readonly TYPE_MAIN = 1;
  public static readonly TYPE_CENTRAL_KEYWORD = 2;
  public static readonly TYPE_CENTRAL_NOT_KEYWORD = 3;
  public static readonly TYPE_PERIPHERAL = 4;

  public processingExit = new Subject<boolean>();

  private envStr: string;
  private tmpData: csvData;

  constructor(
    public userV2Service: UserV2ServiceProvider,
  ) {
    this.setEnv(environment.setting.servicerApiUrl);
    this.serverCacheUpdateMode = environment.setting.serverCacheUpdateMode;
  }

  public resetGlobals(): void {
    environment.constTables = this.defaultConstTables;
    environment.stationTables = this.defaultStationTables;
  };

  public setEnv(servicerUrl: string): void {
    this.envStr = (servicerUrl.indexOf('localhost') < 0) ? '_prod' : '_local';
  }

  public reloadStationTable(): Observable<boolean> {
    return new Observable<boolean>((observe: Subscriber<boolean>) => {
      if (environment.stationTables.stationSession) {
        const userStation = environment.constTables.userStations.find((station: Station) => station.code === environment.stationTables.stationSession.code);
        this.getStationTable(userStation).subscribe((saveResult: boolean) => {
          observe.next(saveResult);
        });
      }
    })
  }

  /**
   * 降車地一覧を取得
   *
   * @param userStation 
   * @param stationTables 
   * @returns 降車地一覧情報の取得成功失敗
   */
  public getStationTable(userStation: Station): Observable<boolean> {
    return new Observable<boolean>((observe: Subscriber<boolean>) => {
      this.userV2Service.doStationCode(environment.APIaccessUserId, userStation['code']).subscribe((response) => {
        if (response.result !== ApiResultType.SUCCESS) {
          observe.next(false);
          return;
        }
        
        const stationName = (environment.stationTables.displayName) ? environment.stationTables.displayName : userStation['name'];

        //駅情報と降車地候補以外を、保持
        const localStationTables = {
          DoStationCodes: null,
          stationSession: null,
          assignName: environment.stationTables.assignName,
          displayName: environment.stationTables.displayName
        };

        //降車地一覧を取得
        localStationTables.DoStationCodes = MapperUtil.mapperUserPuStationCodesV2ApiResponseToDoStationCodes(
          response, environment.constTables.dataCsv['stationsForDoSearch']);
        
        localStationTables.stationSession = {
          id: userStation['id'],
          language: environment.setting.language,
          code: userStation['code'],
          name: stationName,
          lat: userStation['lat'],
          lon: userStation['lon'],
          iconUrl: userStation['iconUrl'],
          imgUrl: userStation['imgUrl']
        };

        //初期化
        environment.stationTables = this.defaultStationTables;

        //取得した降車地情報を格納
        environment.stationTables = localStationTables;

        //ストレージの初期化と更新
        const storageKey = this.getStorageKey('stationTable');
        localStorage.removeItem(storageKey);
        localStorage.setItem(storageKey, JSON.stringify(localStationTables));
        observe.next(true);
        return;
      });
    })

  }


  /**
   * 駅関連情報を初期化
   * @returns 初期化の成功失敗
   */
  public initStationList(): Observable<boolean> {
    return new Observable<boolean>((observe: Subscriber<boolean>) => {
      //environment上に、data.csvがない場合
      if (!environment.constTables.dataCsv) {
        const key = this.getStorageKey('constTable');
        const tableData = localStorage.getItem(key);
        if (tableData) {
          environment.constTables = JSON.parse(tableData);
        }
      }
      //environment上に、降車地候補地がない場合
      if (!environment.stationTables.DoStationCodes) {
        const key = this.getStorageKey('stationTable');
        const tableData = localStorage.getItem(key);
        if (tableData) {
          environment.stationTables = JSON.parse(tableData);
        }
      }

      this.updateStationList().subscribe((res: boolean) => {
        observe.next(res);
      });
    })

  }

  /**
   *  駅一覧情報の取得とCSV情報の更新
   * @returns 処理の成功、失敗を返す
   */
  private updateStationList(): Observable<boolean> {
    return new Observable<boolean>((observe) => {    
      this.userV2Service.guestLogin('').subscribe((response) => {
        if (response.result !== ApiResultType.SUCCESS) {
          observe.next(false);
          return;
        }        
        this.userV2Service.stations(response.user_id).subscribe((response) => {
          if (response.result !== ApiResultType.SUCCESS) {
            observe.next(false);
            return;
          }
          //初期化
          this.tmpData = this.defaultConstTables;
          this.tmpData.userStations = response.stations;
          this.initCsvData().subscribe(() => {
            observe.next(true);
          });
        });
      });
    })
  }


  /**
   * ストレージキーの取得
   * @param prefix ストレージキーの固定部名
   * @returns ストレージキー
   */
  private getStorageKey(prefix: string): string {
    return prefix + this.envStr;
  }

  /**
   * data.csvとスケジュール情報の取得
   * @returns 
   */
  private async constLoad(): Promise<[string, string]> {
    return await Promise.all([
      this.loadCsv(),
      this.loadTimeSchedule()
    ]);
  }


  private async loadTimeSchedule(): Promise<string> {
    const url = (environment.setting.servicerApiUrl.indexOf('localhost') < 0) ? environment.setting.servicerApiUrl + environment.timeScheduleUrl : 'assets/opening_hours.txt';
    return fetch(url)
      .then(response => {
        return response.text();
      });
  }

  /**
   * csvDataを更新
   */
  private initCsvData(): Observable<void> {
    return new Observable((observe) => {
      this.constLoad().then(([csv, timeSchedule]) => {
        //初期化
        environment.constTables = this.defaultConstTables;
        
        const nowDate = new Date();

        // 次回更新期日を翌日0時に設定
        const tmpDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1, 0, 0, 0);

        if (environment.setting.serverCacheUpdateMode !== this.serverCacheUpdateMode) {
          this.serverCacheUpdateMode = environment.setting.serverCacheUpdateMode;
        }
        this.tmpData.nextLoadDate = tmpDate.getTime();
        this.tmpData.timeSchedule = this.parseTimeSchedule(timeSchedule);
        this.tmpData.dataCsv = this.parseCsvData(csv);
        environment.constTables = this.tmpData;

        const storageKey = this.getStorageKey('constTable');
        localStorage.removeItem(storageKey);
        localStorage.setItem(storageKey, JSON.stringify(this.tmpData));
        observe.next();
      });
    });
  }

  private parseTimeSchedule(text: string): string[][] {
    const lines = text.split(/\r\n|\r|\n/);
    let tmp: string[];
    let tmp2: string[];
    let list: string[][] = [];

    if (lines.length > 1) {
      for (let i = 1; i < lines.length; i++) {
        if (lines[i]) {
          tmp = lines[i].split('：');
          tmp2 = tmp[1].split(' - ');
          list.push([tmp[0], tmp2[0], tmp2[1]]);
        }
      }
    }
    return list;
  }

  private parseCsvData(csvData: string): afterFilterCsvData {
    const tmp = csvData.split(/\r\n|\r|\n/);
    let parseData: afterFilterCsvData = {
      'stations': new Array<StationForSearch>(),
      'stationsForSearch': new Array<StationForSearch>(),
      'stationsForDoSearch': new Array<StationForSearch>(),
      'nicknameList': new Array<Nickname>(),
      'keywordList': new Array<Keyword>()
    };

    for (let index = 1; index < tmp.length; index++) {
      //カンマ区切りで配列に分割
      let row = tmp[index].split(',');
      const data: StationForSearch = {
        id: -1,
        code: row[this.CODE_ROW_COLUMN],
        type: Number.parseInt(row[this.TYPE_ROW_COLUMN]),
        name: row[this.NAME_ROW_COLUMN],
        yomi: row[this.YOMI_ROW_COLUMN],
        address: row[this.ADDRESS_ROW_COLUMN],
        lat: Number.parseFloat(row[this.LAT_ROW_COLUMN]),
        lon: Number.parseFloat(row[this.LON_ROW_COLUMN]),
        districtL: row[this.DISTRICT_L_COLUMN],
        districtS: row[this.DISTRICT_S_COLUMN],
        iconUrl: '',
        imgUrl: '',
        imgDropOffUrl: '',
        nickname: this.parseNickname(row[this.NICKNAME_COLUMN]),
        nicknameKana: this.parseNickname(row[this.NICKNAME_KANA_COLUMN]),
        keyword: this.parseKeyword(row[this.KEYWORD_COLUMN]),
        keywordKana: this.parseKeyword(row[this.KEYWORD_KANA_COLUMN])
      }
      parseData['stations'].push(data);

      for (var i = 0; i < data.nickname.length; i++) {
        var nickname: Nickname = {
          name: data.nickname[i],
          yomi: data.nicknameKana[i],
          station: data
        };

        parseData['nicknameList'].push(nickname);

        if (data.keyword == null) continue;

        for (var j = 0; j < data.keyword[i].length; j++) {
          let element = parseData['keywordList'].find((keyword) => keyword.word === data.keyword[i][j]);
          if (element) {
            element.nickName.push(nickname);
          }
          else {
            var keyword: Keyword = {
              word: data.keyword[i][j],
              yomi: data.keywordKana[i][j],
              nickName: new Array<Nickname>(nickname)
            };

            parseData['keywordList'].push(keyword);
          }
        }
      }
      //typeが0の場合検索候補から外す
      if (data.type === 0) continue;

      parseData['stationsForSearch'].push(data);
      parseData['stationsForDoSearch'].push(data);
    }
    return parseData;

  }

  /**
   * data.csvの情報を取得
   * @returns data.csvの情報
   */
  private async loadCsv(): Promise<string> {
    const url = (environment.setting.servicerApiUrl.indexOf('localhost') < 0) ?
      environment.setting.servicerApiUrl + environment.stationCsvUrl : 'assets/data.csv';
    return fetch(url)
      .then(response => {
        return response.text();
      });
  }

  /**
   * ニックネームを'/'で分割
   *  
   * @param nickNames ニックネームの文字列
   * @returns ニックネームリスト
   */
  private parseNickname(nickNames: string): string[] {
    return nickNames.split('/');
  }

  /**
   * キーワードを'/'で分割
   *
   * @param keyword キーワード文字列
   * @returns キーワードリスト
   */
  private parseKeyword(keyword: string): string[][] {
    let keywords: string[][]
    if (!keyword) return keywords;
    const regex = /\{([^\[\}\s]+)/g;
    const point = keyword.match(regex)
      .map((s) => s.substring(1, s.length));
    if (!point) return keywords;

    keywords = new Array(point.length);
    for (var i = 0; i < point.length; i++) {
      keywords[i] = point[i].split('/');
    }
    return keywords;
  }
}

export interface Nickname {
  name: string;
  yomi: string;
  station: StationForSearch;
}

export interface Keyword {
  word: string;
  yomi: string;
  nickName: Nickname[];
}

export interface csvData {
  userStations: Station[];
  timeSchedule: string[][];
  dataCsv: afterFilterCsvData,
  nextLoadDate: number;
}

export interface afterFilterCsvData {
  stations: StationForSearch[];
  stationsForSearch: StationForSearch[];
  stationsForDoSearch: StationForSearch[];
  nicknameList: Nickname[];
  keywordList: Keyword[];
}

export interface saveData {
  DoStationCodes: DoStationCodes;
  stationSession: StationSession;
  assignName: string;
  displayName: string;
}