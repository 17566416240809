import { pageInfo } from "../../models/paga-info";


export class SavePageInfo {

  private pageList = {
    pageIndex: 0,
    pageStatus: []
  } as pageInfo;

  constructor(
  ) { }

  public setPageInfo(index: number, statusList: any) {
    this.pageList.pageIndex = index;
    this.pageList.pageStatus = statusList;
  }

  public getPageInfo() {
    return this.pageList;
  }

  public clearPageInfo() {
    this.pageList.pageIndex = 0;
    this.pageList.pageStatus = null;
  }

}
