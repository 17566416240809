import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { CacheUpdateService } from '../providers/servicer/cache-update-service';
import { Subscription } from 'rxjs';
import { UpdatesAlertService } from './services/updates-alert.service';
import { SwUpdate } from '@angular/service-worker';
import { UpdateService } from '../providers/servicer/update-service';
import { StorageWrapperService } from './services/storage-wrapper.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.html',
  styleUrls: ['app.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyApp implements OnInit, OnDestroy {
  public rootPage = 'home';

  private cacheUpdateSubscription: Subscription;


  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private cacheUpdateService: CacheUpdateService,
    private alertCtrl: AlertController,
    private updateService: UpdateService,
    private swUpdate: SwUpdate,
    private updateAlert: UpdatesAlertService,
    private storageWrapperService: StorageWrapperService
  ) {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.cacheUpdateService.setTimerForMidnight();
    this.updateAlert = new UpdatesAlertService(
      this.alertCtrl,
      this.swUpdate,
      this.updateService,
      this.storageWrapperService
    );

    //起動時に一度更新確認実行
    this.updateAlert.checkForUpdateVersion();
  }

  ngOnDestroy(): void {
    if (this.cacheUpdateSubscription) {
      this.cacheUpdateSubscription.unsubscribe();
      this.cacheUpdateSubscription = null;
    }
  }
}
