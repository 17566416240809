import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiResultType } from './types/api-result-type';
import { LoadingProvider } from '../loading/loading';
import { UserV2ServiceProvider } from './user-v2-service';
import { UserStatusType } from './types/user-status-type';
import { Const } from '../const';
import { Reservation } from './models/reservation';

/**
 * 予約表示するユーザーステータスではないデータをローカルストレージから削除するプロバイダー
 */
@Injectable()
export class ManageStorageAroundReservationCardsService {

  /** 予約カードを表示するユーザステータスのリスト. */
  private readonly USER_STATUS_LIST_DISPLAY_RESERVATION_CARD = [
    UserStatusType.GUIDANCE_RIDE_PLACE,
    UserStatusType.WAITING_USER,
    UserStatusType.WAITING_VEHICLE,
    UserStatusType.WAITING_AUTHENTICATION,
    UserStatusType.USER_AUTHENTICATION,
    UserStatusType.WAITING_RIDE,
    UserStatusType.RIDING
  ];

  /** 予約されているユーザーIDリスト */
  private reservationUserIdList: number[];
  //精度
  private readonly ACCURACY = 0;

  constructor(
    public userV2Service: UserV2ServiceProvider,
    public loading: LoadingProvider
  ) {
  }

  /**
   * ローカルストレージから取得した予約情報内のuserIdが、入力引数のuserIdに含まれているか確認する
   * @param reservations  バックエンドから取得した予約情報一覧
   */
  public checkReservationUserId(reservations: Reservation[]): void{
    // ローカルストレージから予約されているユーザーIDリストを取得
    this.reservationUserIdList = JSON.parse(localStorage.getItem(Const.reservationUserIdListStorageKey));

    if (this.reservationUserIdList) {
      // 予約一覧のuserIdリスト作成
      const reservationsUserIdList: number[] = reservations.map((reservation: Reservation): number => (reservation.user.id));
    
      const localStorageUserIdList: number[] = this.reservationUserIdList;
      
      //ローカルストレージのuserIdの数だけループ
      localStorageUserIdList.forEach((userId) =>{
        //予約一覧に含まれていない場合
        if(!reservationsUserIdList.includes(userId)){
          this.deleteReservationInfoFromLocalStorage(userId);
        }
      })
    } 
  }

  /**
   * 予約情報のユーザーステータスを確認し、予約カードを表示しないステータスの場合ローカルストレージから削除する
   * @param userId ユーザーID
   */
  private deleteReservationInfoFromLocalStorage(userId: number): void{
    //配車確認APIを実行して予約情報を取得
    this.userV2Service.dispatchConfirmation({
        user_id: userId,
        lat: environment.stationTables.stationSession.lat,
        lon: environment.stationTables.stationSession.lon,
        accuracy: this.ACCURACY,
        language: environment.setting.language
      }).pipe(
        tap((response) => {
          if (response.result !== ApiResultType.SUCCESS) {
            //定期的に実行される処理の為エラーはスルー
            return;
          }
      
          //ローカルストレージ上に存在するuserIdのuserStatusを確認し、userStatusが7~13以外の場合ローカルストレージから削除する
          if (!this.USER_STATUS_LIST_DISPLAY_RESERVATION_CARD.includes(response.user_status)) {
            //対象データを削除
            this.reservationUserIdList = this.reservationUserIdList.filter((id) => id !== userId);
            //対象データ削除後のデータをローカルストレージにセット
            localStorage.setItem(Const.reservationUserIdListStorageKey, JSON.stringify(this.reservationUserIdList));
          }
        }),
        catchError(() => {
          //定期的に実行される処理の為エラーはスルー
          return of(null);
        })
      ).subscribe();
  }
}