import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // 設定画面
  {
    path: 'setting',
    loadChildren: () => import('../pages/settings/settings.module')
      .then(m => m.SettingsPageModule)
  },
  
  // 施設管理画面
  {
    path: 'facility-management',
    loadChildren: () => import('../pages/facility-management/facility-management.module')
      .then(m => m.FacilityManagementPageModule)
  },

  // ホーム画面
  {
    path: 'home',
    loadChildren: () => import('../pages/home/home.module')
      .then( m => m.HomePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules ,useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
