import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, interval, of } from 'rxjs';
import { catchError, filter, mergeMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ApiResultType } from '../../providers/servicer/types/api-result-type';
import { AppMonitorV2Parameter } from './models/app-monitor-v2-parameter';
import { AppMonitorV2Response } from './models/app-monitor-v2-response';
import { ServicerProvider } from './servicer';
import { Const } from '../../providers/const';

/**
 * アプリ監視V2サービスプロバイダー.
 */
@Injectable()
export class AppMonitorV2ServiceProvider extends ServicerProvider {
  /** パラメータ. */
  parameter = {
    appName: Const.appName,
    sessionId: null,
    foregrounded: true,
    additionalInfo: null
  } as AppMonitorV2Parameter;

  /** インターバルのSubscription. */
  private intervalSubscription: Subscription = null;

  constructor(
    public http: HttpClient
  ) {
    super(http);
    this.init();
  }

  /**
   * 初期処理.
   */
  init() {
    // 以下のイベントはcordovaで上のみ実行される
    document.addEventListener('deviceready', () => {
      // アプリがバックグラウンドに移行
      document.addEventListener('pause', () => {
        this.parameter.foregrounded = false;
      });

      document.addEventListener('resume', () => {
        // アプリがフォアグラウンドに移行
        setTimeout(() => {
          this.parameter.foregrounded = true;
        }, 0);
      });
    });
  }

  /**
   * 開始.
   */
  start() {
    // 初回の実行
    this.execute().subscribe(() => {
      let processing = false;

      this.intervalSubscription = interval(environment.setting.appStateMonitoringInterval)
        .pipe(filter(() => !processing))
        .pipe(tap(() => processing = true))
        .pipe(mergeMap(() => this.execute()))
        .pipe(tap(() => processing = false))
        .subscribe();
    });
  }

  /**
   * 停止.
   */
  stop() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
  }

  /**
   * アプリ監視の情報を送信する.
   *
   * @returns Observable<AppMonitorV2Response>
   */
  execute(): Observable<AppMonitorV2Response> {
    return this.appMonitor(this.parameter)
      .pipe(
        tap((response) => {
          if (response.result === ApiResultType.SUCCESS) {
            this.parameter.sessionId = response.sessionId;
          }
        }),
        catchError(() => of({} as AppMonitorV2Response))
      );
  }

  /**
   * アプリ監視の情報を送信する.
   *
   * @param parameter AppMonitorV2Parameter
   * @returns Observable<AppMonitorV2Response>
   */
  appMonitor(parameter: AppMonitorV2Parameter): Observable<AppMonitorV2Response> {
    return this.post<string, AppMonitorV2Response>(
      '/v2/appMonitor',
      this.parameterToBody(parameter));
  }
}
